import colors from "colors";

export const styles = {
    primaryButton: {
        backgroundColor: colors.darkBlue,
        borderColor: colors.darkBlue,
        color: "white"
    },
    secondaryButton: {
        backgroundColor: colors.orangyPink,
        borderColor: colors.orangyPink,
        color: "white"
    },
};