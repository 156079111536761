import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { 
    setEmail, 
    setPassword,
    resetLoginState
} from '../../reducers/loginSlice';
import { 
    resetRegistrationState
} from '../../reducers/registrationSlice';

import { Link } from 'react-router-dom';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import colors from '../../colors';

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import API_URL from 'config';

const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const email = useSelector((state) => state.login.email);
  const password = useSelector((state) => state.login.password);

  const handleEmailChange = (event) => {
    dispatch(setEmail({ email: event.target.value }));
  };
  const handlePasswordChange = (event) => {
    dispatch(setPassword({ password: event.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // prepare data
    var data = { 
        email: email,
        password: password
    };

    // POST data
    var userPostUrl = `${API_URL}/api/login`
    window.fetch(userPostUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // Convert the Javascript object to a JSON string
    })
    .then(request => request.json())
    .then((json) => {
        console.debug(json);
        if (json.status === 200){
            toast.success(`Successfully logged in as ${email}`, {
                theme: 'colored'
            });

            // store jwt in local storage (TODO: fix?)
            localStorage.setItem('token', json.token);

            // reset login and registration states
            dispatch(resetLoginState())
            dispatch(resetRegistrationState())

            // go to dashboard
            navigate("/dashboard");

        } else if (json.status === 401) {
            toast.warn(`Invalid email or password! Please try again.`, {
                theme: 'colored'
            });
        } else if (json.status === 423) {
            toast.warn(`Email ${email} not yet verified! Please check your inbox.`, {
                theme: 'colored'
            });
        } else {
            console.error(json)
            toast.error(`Something unexpected happened trying to login as ${email}`, {
                theme: 'colored'
            });
        }
    })
    .catch(error => {
        console.error(error)
        toast.error(`Failed to login as as ${email}`, {
        theme: 'colored'
        });
    });
  };

  return (
    <Card>
        <Card.Header style={{ textAlign: "center"}}>
            <h1>
            {t('common.loginWordCapitalized')}
            </h1>
        </Card.Header>
        <Card.Body style={{ position: "relative" }}>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>{t('common.emailLabel')}</Form.Label>
                    <Form.Control 
                    type="email" 
                    placeholder={t('common.emailLabel')}
                    name="email" 
                    value={email} 
                    onChange={handleEmailChange} 
                    />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>{t('common.passwordLabel')}</Form.Label>
                    <Form.Control 
                    type="password" 
                    placeholder={t('common.passwordLabel')} 
                    name="password" 
                    value={password} 
                    onChange={handlePasswordChange} 
                    />
                </Form.Group>

                <Form.Group className="mb-4">
                    <Button
                    style={{ 
                        backgroundColor: colors.orangyPink, 
                        borderColor: colors.orangyPink
                    }}
                    className="w-100"
                    type="submit"
                    disabled={ !email || !password }
                    >
                    {t('common.submitButton')}
                    </Button>
                </Form.Group>
            </Form>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <span>
                    {t('login.notYetRegisteredMessage')} {t('login.clickMessage')} <Link to="/registration">{t('login.hereMessage')}</Link>
                    </span>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <span>
                    {t('login.forgotPasswordMessage')} {t('login.clickMessage')} <Link to="/forgot-password">{t('login.hereMessage')}</Link>
                    </span>
                </Col>
            </Row>
        </Card.Body>
    </Card>
  );
}

export default LoginForm;
