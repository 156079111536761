import React, { useState } from 'react';

import RegistrationForm from './RegistrationForm';
import Message from './RegistrationMessage';

const Registration = () => {
  const [registrationReceived, setPreRegistrationReceived] = useState(false)

  return (
    <>
      {!registrationReceived ? <RegistrationForm registrationReceived={registrationReceived} setPreRegistrationReceived={setPreRegistrationReceived}/> : <Message />}
    </>
  );
};

export default Registration;
