import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useTranslation } from "react-i18next";

function RegistrationMessage() {
  const { t } = useTranslation();

  return (
    <Card className="d-flex align-items-center justify-content-center">
        <Card.Body>
            <Card.Text>
              {t('registration.registrationSuccessMessage1')}
              {t('registration.registrationSuccessMessage2')}<Link to="/login">{t('common.loginWord')}</Link>
            </Card.Text>
        </Card.Body>
    </Card>
  );
}

export default RegistrationMessage;

