import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
    setNewPassword,
    setNewPasswordConfirmation,
    resetResetPasswordState
} from '../../reducers/resetPasswordSlice';


import { Form, Button, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';

import colors from '../../colors';

import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { isNotNullAndNotUndefinedAndNotEmpty } from 'components/utils';
import API_URL from 'config';

const ResetPasswordForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [token, setToken] = useState(null);
    const [errorMsg, setErrorMsg] = useState(""); // local error state

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        if (isNotNullAndNotUndefinedAndNotEmpty(token)) {
            setToken(token);
        }
        else {
            navigate("/login")
        }
    }, [location, navigate]);

    const newPassword = useSelector((state) => state.resetPassword.newPassword);
    const newPasswordConfirmation = useSelector((state) => state.resetPassword.newPasswordConfirmation);

    const handlePasswordChange = (event) => {
        dispatch(setNewPassword({ newPassword: event.target.value }));
        checkPasswordsEqual(event)
    };
    const handlePasswordConfirmationChange = (event) => {
        dispatch(setNewPasswordConfirmation({ newPasswordConfirmation: event.target.value }));
        checkPasswordsEqual(event)
    };
    const checkPasswordsEqual = (e) => {
        if (e.target.name === "newPasswordConfirmation") {
            if (newPassword !== e.target.value) {
                setErrorMsg("Passwords don't match");
            } else {
                setErrorMsg("");
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // prepare data
        var data = {
            newPassword: newPassword,
            newPasswordConfirmation: newPasswordConfirmation,
        };

        // POST data
        var resetPasswordPostUrl = `${API_URL}/api/user/reset-password`
        window.fetch(resetPasswordPostUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data), // Convert the Javascript object to a JSON string
        })
            .then(request => request.json())
            .then((json) => {
                console.debug(json);
                if (json.status === 200) {
                    toast.success(`Password successfully reset!`, {
                        theme: 'colored'
                    });

                    // reset login and registration states
                    dispatch(resetResetPasswordState())

                    // go to dashboard
                    navigate("/login");

                } else if (json.status === 400) {
                    toast.warn(json.message, {
                        theme: 'colored'
                    });
                } else {
                    console.error(json)
                    toast.error(`Something unexpected happened trying to submit password reset`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error(error)
                toast.error(`Failed to submit password reset`, {
                    theme: 'colored'
                });
            });
    };

    return (
        <Card>
            <Card.Header style={{ textAlign: "center" }}>
                <h1>
                    {t('resetPassword.title')}
                </h1>
            </Card.Header>
            <Card.Body style={{ position: "relative" }}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="newPassword">
                        <Form.Label>{t('common.passwordLabel')}</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder={t('common.passwordLabel')}
                            name="newPassword"
                            value={newPassword}
                            onChange={handlePasswordChange}
                            className={errorMsg ? "is-invalid" : ""}

                        />
                    </Form.Group>
                    <Form.Group controlId="newPasswordConfirmation">
                        <Form.Label>{t('common.confirmPasswordLabel')}</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder={t('common.confirmPasswordLabel')}
                            name="newPasswordConfirmation"
                            value={newPasswordConfirmation}
                            onChange={handlePasswordConfirmationChange}
                            className={errorMsg ? "is-invalid" : ""}

                        />
                        <Form.Control.Feedback type="invalid">
                            {errorMsg}
                        </Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group className="mb-4">
                        <Button
                            style={{
                                backgroundColor: colors.orangyPink,
                                borderColor: colors.orangyPink
                            }}
                            className="w-100"
                            type="submit"
                            disabled={
                                newPassword === "" ||
                                newPasswordConfirmation === "" ||
                                newPassword !== newPasswordConfirmation}
                        >
                            {t('common.submitButton')}
                        </Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

export default ResetPasswordForm;
