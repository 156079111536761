import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  realtors: [],
  regions: [],
  cities: [],
  creditScores: [],
}
const extraDataSlice = createSlice({
  name: 'extraData',
  initialState: initialState,
  reducers: {
    setExtraData: (state, action) => {
      const fieldName = action.payload.fieldName;
      const fieldValue = action.payload.fieldValue;
      state[fieldName] = fieldValue;
    },
    resetExtraData: () => initialState,
  }
})

export const { 
  setExtraData,
  resetExtraData
} = extraDataSlice.actions;
export default extraDataSlice.reducer;
