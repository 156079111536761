import React from 'react';
import Select from 'react-select';
import { Form, Card, Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';

// import Divider from 'components/common/Divider';

// import { useTranslation } from "react-i18next";

// import {setPreapprovalApplicationField} from 'reducers/preapprovalApplicationSlice';
import colors from 'colors';
import { isNotNullAndNotUndefined } from 'components/utils';


const AssetsAndLiabilitiesCommonContent = ({
    applicantGrossAnnualRevenue,
    applicantExpenses,
    applicantApproximateCreditScore,
    applicantEverFiledBankruptcy,
    applicantDischargedDateUTC,

    grossAnnualRevenueChangeHandler,
    expensesChangeHandler,
    approximateCreditScoreChangeHandler,
    everFiledBankruptcyChangeHandler,
    dischargedDateUTCChangeHandler
}) => {
    // const { t } = useTranslation();

    const creditScoreOptions = useSelector((state) => state.extraData.creditScores)
        .flatMap(x => [{ "value": x.id, "label": x.name }]);

    const nullableApplicantDischargedDateUTC = applicantDischargedDateUTC
        ? new Date(applicantDischargedDateUTC)
        : null;

    return (
        <Row>
            <Col>
                <Form>
                    <Row className="mb-3">
                        <Form.Label>YOUR FINANCES: </Form.Label>
                        <Col>
                            <Form.Group controlId="grossAnnualRevenue">
                                <Form.Label>Gross Annual Revenue <span style={{ color: colors.orangyPink }}>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="What is your gross annual revenue?"
                                    name="grossAnnualRevenue"
                                    value={applicantGrossAnnualRevenue}
                                    onChange={grossAnnualRevenueChangeHandler}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Card.Text style={{ color: colors.darkBlue }}>
                                <b>YOUR ANNUAL REVENUES :</b>
                                <br />
                                <span>
                                    No simple salary, or freshly hired, we do understand. However, to insure your success in buying your home at the end of the contract, we verify that you maintain a reasonable annual income, which can be calculated as an average of your earnings over the past 6 months unless you have already annual entrees.
                                </span>
                            </Card.Text>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            {/* <Form.Group controlId="downPayment">
                                <Form.Label>Downpayment <span style={{ color: colors.orangyPink }}>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="How much downPayment do you have?"
                                    name="downPayment"
                                    value={downPayment}
                                    onChange={handleDownpaymentChange}
                                />
                            </Form.Group> */}
                            {/* <Form.Group controlId="hasRrsp">
                                <Form.Label>RRSP</Form.Label>
                                <Select
                                    name="hasRrsp"
                                    placeholder="Do you have any RRSP?"
                                    options={[
                                        { value: '0', label: 'No' },
                                        { value: '1', label: 'Yes' },
                                        // add more options as needed
                                    ]}
                                    value={hasRrsp}
                                    onChange={handleHasRrspChange}
                                />
                            </Form.Group> */}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Group controlId="currentMonthlyDebts">
                                <Form.Label>Current Monthly Expenses <span style={{ color: colors.orangyPink }}>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="What are your current monthly debts?"
                                    name="currentMonthlyDebts"
                                    value={applicantExpenses}
                                    onChange={expensesChangeHandler}
                                />
                            </Form.Group>
                            <Form.Group controlId="approximateCreditScore">
                                <Form.Label>Approximate Credit Score <span style={{ color: colors.orangyPink }}>*</span></Form.Label>
                                <Select
                                    name="approximateCreditScore"
                                    placeholder="What is your approximate credit score?"
                                    options={creditScoreOptions}
                                    value={applicantApproximateCreditScore}
                                    onChange={approximateCreditScoreChangeHandler}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Card.Text style={{ color: colors.darkBlue }}>
                                <b>DEBTS AND CREDIT SCORE :</b>
                                <br />
                                <span>
                                    Capacity to purchase depends on the amount of money available each month. Unfortunately, any monthly loan payment, car payment or credit card paymentmust be considered as monthly debts. Plus, we want to help you reach a certain credit score and that is why we need to evaluate where you are at now.
                                </span>
                            </Card.Text>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Group controlId="everFiledBankruptcy">
                                <Form.Label>Bankruptcy <span style={{ color: colors.orangyPink }}>*</span></Form.Label>
                                <Select
                                    name="everFiledBankruptcy"
                                    placeholder="Have you ever filed for bankruptcy?"
                                    options={[
                                        { value: '0', label: 'No' },
                                        { value: '1', label: 'Yes' },
                                        // add more options as needed
                                    ]}
                                    value={applicantEverFiledBankruptcy}
                                    onChange={everFiledBankruptcyChangeHandler}
                                />
                            </Form.Group>
                            {(isNotNullAndNotUndefined(applicantEverFiledBankruptcy) && applicantEverFiledBankruptcy.label === "Yes")
                                ? <Form.Group controlId="dischargedDate">
                                    <Form.Label>Date Discharged <span style={{ color: colors.orangyPink }}>*</span></Form.Label>
                                    <br />
                                    <DatePicker
                                        selected={nullableApplicantDischargedDateUTC}
                                        onChange={dischargedDateUTCChangeHandler} />
                                </Form.Group>
                                : <></>}
                        </Col>
                        <Col>
                            <Card.Text style={{ color: colors.darkBlue }}>
                                <b>BANKRUPTCY :</b>
                                <br />
                                <span>
                                    If you are not familiar with bankruptcy or consumer proposal it means you are not concerned with this question. However, if you had one we can still help you get on your feet to own a home. The only condition is that you have been discharged for more than a year to make sure the bank approves you for a loan after 3 years.
                                </span>
                            </Card.Text>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
}

export default AssetsAndLiabilitiesCommonContent;
