import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  isAccepted: false
}
const registrationSlice = createSlice({
  name: 'registration',
  initialState: initialState,
  reducers: {
    setRegistration: (state, action) => {
      const fieldName = action.payload.fieldName;
      const fieldValue = action.payload.fieldValue;
      state[fieldName] = fieldValue;
    },
    resetRegistrationState: () => initialState,
  }
})

export const { 
  setRegistration,
  resetRegistrationState
} = registrationSlice.actions;
export default registrationSlice.reducer;
