import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ApplicantTabCommonContent from './applicantTabCommonContent';

import { setPreapprovalApplicationField } from 'reducers/preapprovalApplicationSlice';


const CoApplicantTabContent = () => {
  // const { t } = useTranslation();

  const dispatch = useDispatch();

  // obtain preapproval form information from redux state
  const coApplicantFirstName = useSelector((state) => state.preapprovalApplication.coApplicantFirstName);
  const coApplicantLastName = useSelector((state) => state.preapprovalApplication.coApplicantLastName);
  const coApplicantEmail = useSelector((state) => state.preapprovalApplication.coApplicantEmail);
  const coApplicantMobilePhoneNumber = useSelector((state) => state.preapprovalApplication.coApplicantMobilePhoneNumber);

  return (
    <ApplicantTabCommonContent
      applicantFirstName={coApplicantFirstName}
      applicantLastName={coApplicantLastName}
      applicantEmail={coApplicantEmail}
      applicantMobilePhoneNumber={coApplicantMobilePhoneNumber}

      setApplicantFirstNameFunction={(e) => {
        const newValue = e.target.value;
        // Only update the value if it contains only alphabetic characters or a dash
        if (/^[a-zA-Z-]*$/.test(newValue)) {
          dispatch(setPreapprovalApplicationField({
            fieldName: "coApplicantFirstName",
            fieldValue: e.target.value
          }))
        }
      }}
      setApplicantLastNameFunction={(e) => {
        const newValue = e.target.value;
        // Only update the value if it contains only alphabetic characters or a dash
        if (/^[a-zA-Z-]*$/.test(newValue)) {
          dispatch(setPreapprovalApplicationField({
            fieldName: "coApplicantLastName",
            fieldValue: e.target.value
          }))
        }
      }}
      setApplicantEmailFunction={(e) => dispatch(setPreapprovalApplicationField({
        fieldName: "coApplicantEmail",
        fieldValue: e.target.value
      }))}
      setApplicantMobilePhoneNumberFunction={(e) => dispatch(setPreapprovalApplicationField({
        fieldName: "coApplicantMobilePhoneNumber",
        fieldValue: e.target.value
      }))}

      displaySideText={false}
    />
  );
}

export default CoApplicantTabContent;
