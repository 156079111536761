import React from 'react';
import Card from 'react-bootstrap/Card';
import colors from 'colors';
import { useTranslation } from "react-i18next";

const UnderConstruction = () => {
  const { t } = useTranslation();

  return (
    <Card className="text-center">
      <Card.Body>
        <Card.Title>{t('dashboard.underConstruction.header')}</Card.Title>
        <Card.Text style={{ color: colors.darkBlue }}>
          {t('dashboard.underConstruction.message')}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
export default UnderConstruction;