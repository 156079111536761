// import logo from './logo.svg';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';

// import React, { useEffect } from 'react';
import React from 'react';
import { IntlProvider } from 'react-intl';
// import { useSelector, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// import Cookies from 'js-cookie';

// import { 
//   setPreapprovalFetchingState,
//   updatePreapproval
// } from './reducers/userSlice';


import BaseLayout from './layouts/base';
import Dashboard from './layouts/dashboard';

import './i18n';

function App() {
  const language = useSelector((state) => state.language);

  return (
    <IntlProvider locale={language} >
      <Router>
          <Routes>
              <Route path="/*" element={<BaseLayout />} />
              <Route path="/dashboard/*" element={<Dashboard />} />
          </Routes>
      </Router>
      <ToastContainer/>
    </IntlProvider>
  );
}

export default App;
