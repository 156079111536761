import React, { useState } from 'react';
import { Form, Card, Row, Col } from 'react-bootstrap';
import InputMask from 'react-input-mask';

import colors from 'colors';

// import { useState } from 'react-redux';

// import Divider from 'components/common/Divider';

// import { useTranslation } from "react-i18next";

const ApplicantTabCommonContent = ({
    applicantFirstName,
    applicantLastName,
    applicantEmail,
    applicantMobilePhoneNumber,

    setApplicantFirstNameFunction,
    setApplicantLastNameFunction,
    setApplicantEmailFunction,
    setApplicantMobilePhoneNumberFunction,

    displaySideText
}) => {
    // const { t } = useTranslation();

    // component-localized email text verification
    const [isValid, setIsValid] = useState(true);

    const handleEmailChange = (event) => {
        const newEmail = event.target.value;

        // Regular expression for email validation
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

        // Update the email state and validation state
        setApplicantEmailFunction(event)
        setIsValid(emailRegex.test(newEmail));
    };

    return (
        <Row>
            <Col xs={6}>
                <Form>
                    <Form.Label>YOUR CONTACT INFORMATION : </Form.Label>
                    <Form.Group controlId="applicantFirstName">
                        <Form.Label>First Name <span style={{ color: colors.orangyPink }}>*</span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="What is your first name?"
                            name="applicant1FirstName"
                            value={applicantFirstName}
                            onChange={setApplicantFirstNameFunction}
                        />
                    </Form.Group>
                    <Form.Group controlId="applicantLastName">
                        <Form.Label>Last Name <span style={{ color: colors.orangyPink }}>*</span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="What is your last name?"
                            name="applicant1LastName"
                            value={applicantLastName}
                            onChange={setApplicantLastNameFunction}
                        />
                    </Form.Group>
                    <Form.Group controlId="applicantEmail">
                        <Form.Label>Email <span style={{ color: colors.orangyPink }}>*</span></Form.Label>
                        <Form.Control
                            placeholder={"What is your email address?"}
                            value={applicantEmail}
                            name="email"
                            isInvalid={!isValid}
                            onChange={handleEmailChange}
                            type="email"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid email address.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="applicantMobilePhoneNumber">
                        <Form.Label>Mobile Phone Number</Form.Label>
                        <InputMask
                            mask="(999) 999-9999"
                            maskChar={null}
                            value={applicantMobilePhoneNumber}
                            onChange={setApplicantMobilePhoneNumberFunction}>
                            {(inputProps) => <Form.Control
                                type="tel"
                                {...inputProps}
                                placeholder="What is your mobile phone number?"
                                required
                            />}
                        </InputMask>

                        <Form.Control.Feedback type="invalid">
                            Please enter a valid phone number address.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Col>
            {displaySideText && (<Col xs={6}>
                <Card.Text style={{ color: colors.darkBlue }}>
                    <b>YOUR PRE-FILLED CONTACT :</b>
                    <br />
                    <span>
                        The pre-filled contact info is sourced from your profile,
                        which we generated using the registration details you provided. Due to mistakes, or
                        concerns related to trust, not all information may be correct. You can always correct it from here.
                    </span>
                </Card.Text>
                <Card.Text style={{ color: colors.darkBlue }}>
                    <b>YOUR PERSONAL INFORMATION :</b>
                    <br />
                    <span>
                        We highly value  your trust and very much dislike emails or phone harassment. your information
                        is saved and secured in highly secured databases and is only used by us to share with you the results
                        and information about new features.
                    </span>
                </Card.Text>
            </Col>)}
        </Row>
    );
}

export default ApplicantTabCommonContent;
