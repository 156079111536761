import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Tab, Tabs, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

import ApplicantTabContent from './preapproval/applicantTabContent';
import CoApplicantTabContent from './preapproval/coApplicantTabContent';
import ApplicantAssetsAndLiabilitiesTabContent from './preapproval/applicantAssetsAndLiabilitiesTabContent';
import CoApplicantAssetsAndLiabilitiesTabContent from './preapproval/coApplicantAssetsAndLiabilitiesTabContent';
import PropertyTabContent from './preapproval/propertyTabContent';

import API_URL from 'config';
import colors from 'colors';

import {
  getTokenFromLocalStorage,
  isNullOrUndefinedOrEmpty,
  isObjectEmpty
} from 'components/utils';

import {
  setAllPreapprovalApplicationFields,
  setPreapprovalApplicationField,
  resetCoApplicantFields
} from 'reducers/preapprovalApplicationSlice';
import ResultsTabContent from './preapproval/resultsTabContent';

import { styles } from 'components/styles'
// import { useTranslation } from "react-i18next";

const PreApproval = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userFirstName = useSelector((state) => state.user.firstName);
  const hasSecondApplicant = useSelector((state) => state.preapprovalApplication.hasSecondApplicant);

  // required fields
  // -- tab 1
  const isApplicantFirstNameEmpty = useSelector((state) => state.preapprovalApplication.applicantFirstName) === "";
  const isApplicantLastNameEmpty = useSelector((state) => state.preapprovalApplication.applicantLastName) === "";
  const isApplicantEmailEmpty = useSelector((state) => state.preapprovalApplication.applicantEmail) === "";

  const isCoApplicantFirstNameEmpty = useSelector((state) => state.preapprovalApplication.coApplicantFirstName) === "";
  const isCoApplicantLastNameEmpty = useSelector((state) => state.preapprovalApplication.coApplicantLastName) === "";
  const isCoApplicantEmailEmpty = useSelector((state) => state.preapprovalApplication.coApplicantEmail) === "";

  const areTabOneMissingRequiredFields = (
    isApplicantFirstNameEmpty ||
    isApplicantLastNameEmpty ||
    isApplicantEmailEmpty
  ) || (hasSecondApplicant ? (
    isCoApplicantFirstNameEmpty ||
    isCoApplicantLastNameEmpty ||
    isCoApplicantEmailEmpty
  ) : false)
  const tabOneTooltip = (
    <Tooltip id="tooltip-disabled">
      A required applicant or coapplicant information field is missing!
    </Tooltip>
  ); // TODO refactor

  // -- tab 2
  const isApplicantGrossRevenueEmpty = isNullOrUndefinedOrEmpty(useSelector((state) => state.preapprovalApplication.applicantGrossAnnualRevenue));
  const isApplicantCurrentMonthlyDebtsEmpty = isNullOrUndefinedOrEmpty(useSelector((state) => state.preapprovalApplication.applicantExpenses));
  const isApplicantApproximateCreditScoreEmpty = isObjectEmpty(useSelector((state) => state.preapprovalApplication.applicantApproximateCreditScore));
  const applicantEverFiledBankruptcy = useSelector((state) => state.preapprovalApplication.applicantEverFiledBankruptcy)
  const applicantDischargedDateUTC = useSelector((state) => state.preapprovalApplication.applicantDischargedDateUTC)
  const didApplicantEverFiledBankruptcyAndDateDischargedIsMissing =
    (applicantEverFiledBankruptcy.value && applicantEverFiledBankruptcy.value === "1") &&
    isNullOrUndefinedOrEmpty(applicantDischargedDateUTC);

  const isCoApplicantGrossRevenueEmpty = isNullOrUndefinedOrEmpty(useSelector((state) => state.preapprovalApplication.coApplicantGrossAnnualRevenue));
  const isCoApplicantCurrentMonthlyDebtsEmpty = isNullOrUndefinedOrEmpty(useSelector((state) => state.preapprovalApplication.coApplicantExpenses));
  const isCoApplicantApproximateCreditScoreEmpty = isObjectEmpty(useSelector((state) => state.preapprovalApplication.coApplicantApproximateCreditScore));
  const coApplicantEverFiledBankruptcy = useSelector((state) => state.preapprovalApplication.coApplicantEverFiledBankruptcy)
  const coApplicantDischargedDateUTC = useSelector((state) => state.preapprovalApplication.coApplicantDischargedDateUTC)
  const didCoApplicantEverFiledBankruptcyAndDateDischargedIsMissing =
    (coApplicantEverFiledBankruptcy.value && coApplicantEverFiledBankruptcy.value === "1") &&
    isNullOrUndefinedOrEmpty(coApplicantDischargedDateUTC);

  const areTabTwoMissingRequiredFields = (
    isApplicantGrossRevenueEmpty ||
    isApplicantCurrentMonthlyDebtsEmpty ||
    isApplicantApproximateCreditScoreEmpty ||
    didApplicantEverFiledBankruptcyAndDateDischargedIsMissing
  ) || (hasSecondApplicant ? (
    isCoApplicantGrossRevenueEmpty ||
    isCoApplicantCurrentMonthlyDebtsEmpty ||
    isCoApplicantApproximateCreditScoreEmpty ||
    didCoApplicantEverFiledBankruptcyAndDateDischargedIsMissing
  ) : false)

  const tabTwoTooltip = (
    <Tooltip id="tooltip-disabled">
      A required 'Assets & Liabilities' applicant or coapplicant field is missing!
    </Tooltip>
  ); // TODO refactor

  // -- tab 3
  const isRegionOfInterestNullOrEmpty = isNullOrUndefinedOrEmpty(useSelector((state) => state.preapprovalApplication.regionOfInterest));
  const isDownpaymentEmpty = isNullOrUndefinedOrEmpty(useSelector((state) => state.preapprovalApplication.downPayment));

  const acceptsTermsOfServiceAndPrivacyPolicy = useSelector(
    (state) => state.preapprovalApplication.acceptsTermsOfServiceAndPrivacyPolicy);

  const areTabThreeMissingRequiredFields = isRegionOfInterestNullOrEmpty || !acceptsTermsOfServiceAndPrivacyPolicy || isDownpaymentEmpty;

  const tabThreeTooltip = (
    <Tooltip id="tooltip-disabled">
      A required 'Property' field is missing!
    </Tooltip>
  ); // TODO refactor


  const areTabOneOrTabTwoRequiredFieldsMissing = areTabOneMissingRequiredFields || areTabTwoMissingRequiredFields;
  const areTabOneTwoOrThreeRequiredFieldsMissing = areTabOneMissingRequiredFields || areTabTwoMissingRequiredFields || areTabThreeMissingRequiredFields;

  // TODO: refactor
  const fullPreapprovalApplication = useSelector((state) => state.preapprovalApplication);

  const toggleHasSecondApplicant = e => {
    if (hasSecondApplicant) {
      dispatch(resetCoApplicantFields())
    }
    dispatch(setPreapprovalApplicationField({
      fieldName: "hasSecondApplicant",
      fieldValue: !hasSecondApplicant
    }))
  }

  const hasPreapprovalResult = useSelector((state) => state.user.hasPreapprovalResult);

  // local state to handle tabs
  const freshTabState = [false, true, true, true];
  const [activeKey, setActiveKey] = useState('first');
  const [disabled, setDisabled] = useState(freshTabState);

  const [startingOver, setStartingOver] = useState(false);
  const [applicantsActiveTab, setApplicantsActiveTab] = useState('applicant');


  useEffect(() => {

    if (!hasSecondApplicant) {
      setApplicantsActiveTab('applicant')
    }

    // auto jump to results page if exists
    if (hasPreapprovalResult && !startingOver) {
      setDisabled([true, true, true, false]); // disable previous tabs (unless "start over")
      setActiveKey('fourth');

      var existingToken = getTokenFromLocalStorage()

      // preapproval application exists in database. fetch it and populate 
      // the form for potential modifications/updates
      var getExistingUserPreapprovalApplicationUrl = `${API_URL}/api/preapproval`
      window.fetch(getExistingUserPreapprovalApplicationUrl, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + existingToken
        }
      })
        .then(response => response.json())
        .then(json => {
          if (json.status === 200) {
            console.debug("Got existing preapproval application: ", json);

            // set local preapproval state
            dispatch(setAllPreapprovalApplicationFields(json));
          } else {
            console.error(json)
            toast.error(`Something unexpected happened trying to obtain existing preapproval application`, {
              theme: 'colored'
            });
          }
        })
        .catch(error => {
          console.error(error)
          toast.error(`Failed to obtain existing preapproval application`, {
            theme: 'colored'
          });
        });
    }
  }, [dispatch, hasPreapprovalResult, hasSecondApplicant, startingOver]);

  const handleTabSelect = (key) => {
    if (key === 'first') {
      setActiveKey(key);
    } else if (key === 'second' && !disabled[1]) {
      setActiveKey(key);
    } else if (key === 'third' && !disabled[2]) {
      setActiveKey(key);
    } else if (key === 'fourth' && !disabled[3]) {
      setActiveKey(key);
    }
  }
  const handleNextClick = (nextKey, index) => {
    let newDisabled = [...disabled];
    newDisabled[index] = false;
    setDisabled(newDisabled);
    setActiveKey(nextKey);
  };

  const handlePreviousClick = (previousKey) => {
    setActiveKey(previousKey);
  };

  const handleStartOver = (nextKey, index) => {
    setDisabled(freshTabState);
    setActiveKey('first');
    setStartingOver(true)
  };

  const handleBackToDashboard = () => {
    navigate("/dashboard")
  }

  const handleApplicationSubmission = (e) => {
    var existingToken = getTokenFromLocalStorage();

    var applicantDischargedDateUTC = (
      fullPreapprovalApplication.applicantDischargedDateUTC
      && fullPreapprovalApplication.applicantDischargedDateUTC !== ""
    ) // ensure variable is properly formatted (either a date or string depending on context) before formatting
      ? Object.prototype.toString.call(fullPreapprovalApplication.applicantDischargedDateUTC) === "[object Date]"
        ? fullPreapprovalApplication.applicantDischargedDateUTC.toISOString()
        : fullPreapprovalApplication.applicantDischargedDateUTC
      : ""
    var coApplicantDischargedDateUTC = (
      fullPreapprovalApplication.coApplicantDischargedDateUTC
      && fullPreapprovalApplication.coApplicantDischargedDateUTC !== ""
    ) // ensure variable is properly formatted (either a date or string depending on context) before formatting
      ? Object.prototype.toString.call(fullPreapprovalApplication.coApplicantDischargedDateUTC) === "[object Date]"
        ? fullPreapprovalApplication.coApplicantDischargedDateUTC.toISOString()
        : fullPreapprovalApplication.coApplicantDischargedDateUTC
      : ""
    // prepare data
    var data = {
      applicantFirstName: fullPreapprovalApplication.applicantFirstName,
      applicantLastName: fullPreapprovalApplication.applicantLastName,
      applicantEmail: fullPreapprovalApplication.applicantEmail,

      applicantMobilePhoneNumber: fullPreapprovalApplication.applicantMobilePhoneNumber,

      applicantGrossAnnualRevenue: parseFloat(fullPreapprovalApplication.applicantGrossAnnualRevenue),
      applicantExpenses: parseFloat(fullPreapprovalApplication.applicantExpenses),
      applicantApproximateCreditScore: fullPreapprovalApplication.applicantApproximateCreditScore,
      applicantEverFiledBankruptcy: Boolean(Number(fullPreapprovalApplication.applicantEverFiledBankruptcy.value)),
      applicantDischargedDateUTC: applicantDischargedDateUTC,

      coApplicantFirstName: fullPreapprovalApplication.coApplicantFirstName,
      coApplicantLastName: fullPreapprovalApplication.coApplicantLastName,
      coApplicantEmail: fullPreapprovalApplication.coApplicantEmail,

      coApplicantMobilePhoneNumber: fullPreapprovalApplication.coApplicantMobilePhoneNumber,

      coApplicantGrossAnnualRevenue: parseFloat(fullPreapprovalApplication.coApplicantGrossAnnualRevenue),
      coApplicantExpenses: parseFloat(fullPreapprovalApplication.coApplicantExpenses),
      coApplicantApproximateCreditScore: fullPreapprovalApplication.coApplicantApproximateCreditScore,
      coApplicantEverFiledBankruptcy: Boolean(Number(fullPreapprovalApplication.coApplicantEverFiledBankruptcy.value)),
      coApplicantDischargedDateUTC: coApplicantDischargedDateUTC,

      downPayment: parseFloat(fullPreapprovalApplication.downPayment),
      initialPayment: fullPreapprovalApplication.initialPayment,
      // hasRrsp: Boolean(Number(fullPreapprovalApplication.hasRrsp.value)),

      regionOfInterest: fullPreapprovalApplication.regionOfInterest, //.label,
      citiesOfInterest: fullPreapprovalApplication.citiesOfInterest, //.flatMap(i => i.label),
      homeTypesOfInterest: fullPreapprovalApplication.homeTypesOfInterest.flatMap(i => i.label),
      // homePurchaseTimeline: fullPreapprovalApplication.homePurchaseTimeline.label,
      realtor: fullPreapprovalApplication.realtor, //.label,
      // realtorInList: Boolean(Number(fullPreapprovalApplication.realtorInList.value)),

      acceptsTermsOfServiceAndPrivacyPolicy: Boolean(fullPreapprovalApplication.acceptsTermsOfServiceAndPrivacyPolicy),
    }

    // POST data
    var applicationSubmitPostUrl = `${API_URL}/api/preapproval`;
    window.fetch(applicationSubmitPostUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + existingToken
      },
      body: JSON.stringify(data), // Convert the Javascript object to a JSON string
    })
      .then(request => request.json())
      .then((json) => {
        console.debug(json);
        if (json.status === 201) {
          handleNextClick('fourth', 3)
          setDisabled([true, true, true, false]); // disable previous tabs (unless "start over")
          toast.success(`Successfully submitted preapproval application`, {
            theme: 'colored'
          });
        } else {
          console.error(json)
          toast.error(`Something unexpected happened trying to submit the preapproval application`, {
            theme: 'colored'
          });
        }
      })
      .catch(error => {
        console.error(error)
        toast.error(`Failed to submit the preapproval application`, {
          theme: 'colored'
        });
      });
  };

  var tabOneNextButton = () => {
    return <Button
      style={styles.primaryButton}
      disabled={areTabOneMissingRequiredFields}
      onClick={() => handleNextClick('second', 1)}>Next</Button>
  }
  var tabTwoNextButton = () => {
    return <Button
      style={styles.primaryButton}
      disabled={areTabOneOrTabTwoRequiredFieldsMissing}
      onClick={() => handleNextClick('third', 2)}>Next</Button>
  }
  var tabThreeNextButton = () => {
    return <Button
      style={styles.primaryButton}
      disabled={areTabOneTwoOrThreeRequiredFieldsMissing}
      onClick={() => handleApplicationSubmission()}>Submit</Button>
  }


  return (
    <Card>
      <Card.Header>Pre Approval Form</Card.Header>
      <Row>
        <Col>
          <Card.Body>
            <Card.Text style={{ color: colors.darkBlue }}>
              Hi {userFirstName}. please fill-in the following form as best as you can.
              Do not worry - you can always resubmit the information to obtain your purchase capacity.
              our pre-approval does not affect your credit and the information is never shared with any other third party.
            </Card.Text>
            <Row className="mb-3">
              <Tabs defaultActiveKey="applicationInfo"
                activeKey={activeKey}
                onSelect={handleTabSelect}
                id="preapproval-form-tabs">
                <Tab eventKey="first"
                  disabled={disabled[0]}
                  title="Applicant Information">
                  <Button style={styles.primaryButton} variant="primary" className='float-right' onClick={toggleHasSecondApplicant}>
                    {!hasSecondApplicant ? "Add Co-Applicant" : "Remove Co-Applicant"}
                  </Button>
                  <Tabs defaultActiveKey="applicant1"
                    activeKey={applicantsActiveTab} onSelect={key => setApplicantsActiveTab(key)}
                    id="preapproval-applicant-tabs">
                    <Tab eventKey="applicant"
                      title="Applicant">
                      <ApplicantTabContent />
                    </Tab>
                    {hasSecondApplicant && (
                      <Tab eventKey="coApplicant"
                        title="Co-Applicant">
                        <CoApplicantTabContent />
                      </Tab>
                    )}
                  </Tabs>
                  <Row className="justify-content-center">
                    <Col xs="auto">
                      {areTabOneMissingRequiredFields ?
                        <OverlayTrigger overlay={tabOneTooltip}>
                          <span className="d-inline-block">{tabOneNextButton()}</span>
                        </OverlayTrigger> :
                        tabOneNextButton()}
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="second"
                  disabled={disabled[1]}
                  title="Assets & Liabilities">
                  <Tabs defaultActiveKey="applicant1"
                    eventKey="applicant"
                    id="preapproval-applicant-tabs">
                    <Tab eventKey="applicant1"
                      title="Applicant">
                      <ApplicantAssetsAndLiabilitiesTabContent />
                    </Tab>
                    {hasSecondApplicant && (
                      <Tab eventKey="coapplicant"
                        title="Co-Applicant">
                        <CoApplicantAssetsAndLiabilitiesTabContent />
                      </Tab>
                    )}
                  </Tabs>
                  <Row className="justify-content-center">
                    <Col xs="auto">
                      <Button
                        style={styles.secondaryButton}
                        onClick={() => handlePreviousClick('first')}>Previous</Button>
                      {areTabOneOrTabTwoRequiredFieldsMissing ?
                        <OverlayTrigger overlay={tabTwoTooltip}>
                          <span className="d-inline-block">{tabTwoNextButton()}</span>
                        </OverlayTrigger> :
                        tabTwoNextButton()}
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="third"
                  disabled={disabled[2]}
                  title="Property">
                  <PropertyTabContent />
                  <Row className="justify-content-center">
                    <Col xs="auto">
                      <Button
                        style={styles.secondaryButton}
                        onClick={() => handlePreviousClick('second')}>Previous</Button>
                      {areTabOneTwoOrThreeRequiredFieldsMissing ?
                        <OverlayTrigger overlay={tabThreeTooltip}>
                          <span className="d-inline-block">{tabThreeNextButton()}</span>
                        </OverlayTrigger> :
                        tabThreeNextButton()}
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="fourth"
                  disabled={disabled[3]}
                  title="Results">
                  <ResultsTabContent activeKey={activeKey} />
                  <Row className="justify-content-center">
                    <Col xs="auto">
                      <Button style={styles.secondaryButton} onClick={() => handleStartOver()}>Start Over</Button>
                      <Button style={styles.primaryButton} onClick={() => handleBackToDashboard()}>Back to Dashboard</Button>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Row>
            {activeKey !== "fourth" ?
              <Row>
                <Card.Text>
                  Click on any pre-filled box to change it and please make sure to fill the required fields before submitting.
                </Card.Text>
              </Row> : <></>
            }
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
}

export default PreApproval;
