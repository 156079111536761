import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import Divider from 'components/common/Divider';

// import { useTranslation } from "react-i18next";
import AssetsAndLiabilitiesCommonContent from './assetsAndLiabilitiesCommonContent';
import {
    setFieldToEmptyState,
    setPreapprovalApplicationField
} from 'reducers/preapprovalApplicationSlice';


const ApplicantAssetsAndLiabilitiesTabContent = () => {
    // const { t } = useTranslation();

    const dispatch = useDispatch();

    // obtain preapproval form information from redux state
    const grossAnnualRevenue = useSelector((state) =>
        state.preapprovalApplication.coApplicantGrossAnnualRevenue);
    // const downPayment = useSelector((state) =>
    //     state.preapprovalApplication.downPayment);
    // const hasRrsp = useSelector((state) =>
    //     state.preapprovalApplication.hasRrsp);
    const currentMonthlyDebts = useSelector((state) =>
        state.preapprovalApplication.coApplicantExpenses);
    const approximateCreditScore = useSelector((state) =>
        state.preapprovalApplication.coApplicantApproximateCreditScore);
    const everFiledBankruptcy = useSelector((state) =>
        state.preapprovalApplication.coApplicantEverFiledBankruptcy);
    const dischargedDateUTC = useSelector((state) =>
        state.preapprovalApplication.coApplicantDischargedDateUTC);


    const handleGrossAnnualRevenueChange = e => {
        var value = e.target.value
        // Allow the update if the new value is a number
        if (value === '' || /^\d+$/.test(value)) {
            value = Number(value).toString() // remove leading 0's if any
            dispatch(setPreapprovalApplicationField({
                fieldName: "coApplicantGrossAnnualRevenue",
                fieldValue: value
            }))
        }
    }
    // const handleDownpaymentChange = e => {
    //     var value = e.target.value
    //     if (value === '' || /^\d+$/.test(value)) {
    //         dispatch(setDownpayment(parseFloat(value)))
    //     }
    // }
    // const handleHasRrspChange = e => {
    //     dispatch(setHasRrsp(e))
    // }
    const handleCurrentMonthlyDebts = e => {
        var value = e.target.value
        // Allow the update if the new value is a number
        if (value === '' || /^\d+$/.test(value)) {
            value = Number(value).toString() // remove leading 0's if any
            dispatch(setPreapprovalApplicationField({
                fieldName: "coApplicantExpenses",
                fieldValue: value
            }))
        }
    }
    const handleApproximateCreditScoreChange = e => {
        dispatch(setPreapprovalApplicationField({
            fieldName: "coApplicantApproximateCreditScore",
            fieldValue: e
        }))
    }
    const handleEverFiledBankruptcyChange = e => {
        dispatch(setPreapprovalApplicationField({
            fieldName: "coApplicantEverFiledBankruptcy",
            fieldValue: e
        }))
        if (e.value === "0") {
            // clear discharged date
            dispatch(setFieldToEmptyState({
                fieldName: "coApplicantDischargedDateUTC"
            }))
        }
    }
    const handleDischargedDateChange = e => {
        dispatch(setPreapprovalApplicationField({
            fieldName: "coApplicantDischargedDateUTC",
            fieldValue: e
        }))
    }

    return (
        <AssetsAndLiabilitiesCommonContent
            applicantGrossAnnualRevenue={grossAnnualRevenue}
            applicantExpenses={currentMonthlyDebts}
            applicantApproximateCreditScore={approximateCreditScore}
            applicantEverFiledBankruptcy={everFiledBankruptcy}
            applicantDischargedDateUTC={dischargedDateUTC}

            grossAnnualRevenueChangeHandler={handleGrossAnnualRevenueChange}
            expensesChangeHandler={handleCurrentMonthlyDebts}
            approximateCreditScoreChangeHandler={handleApproximateCreditScoreChange}
            everFiledBankruptcyChangeHandler={handleEverFiledBankruptcyChange}
            dischargedDateUTCChangeHandler={handleDischargedDateChange}
        />
    );
}

export default ApplicantAssetsAndLiabilitiesTabContent;
