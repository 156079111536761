import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Card, Row, Col } from 'react-bootstrap';

import Divider from '../common/Divider';

import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faHome, 
  faFileLines, 
  faMagnifyingGlass, 
  faFileUpload,
  faQuestion, 
  faFileContract,
  faGear} from '@fortawesome/free-solid-svg-icons'
  
import colors from '../../colors';
var linkStyles = {
  color: colors.darkBlue, 
  textDecoration: 'none'
}
var disabledLinkStyles = {
  color: "grey", 
  pointerEvents: "none",
  textDecoration: 'none'
}

const Menu = () => {
  const { t } = useTranslation();

  const hasPreapprovalResult = useSelector((state) => state.user.hasPreapprovalResult);

  return (
      <Card>
        <Card.Header>{t("dashboard.menu.header")}</Card.Header>
        <Card.Body>
          <Nav defaultActiveKey="/dashboard" className="flex-column">
            <Link to="/dashboard" style={linkStyles}>
              <Row>
                <Col xs={2}><FontAwesomeIcon icon={faHome} /></Col>
                <Col>{t("dashboard.menu.overview")}</Col>
              </Row>
            </Link>
            <Link to="/dashboard/preapproval" 
                style={linkStyles}>
              <Row>
                <Col xs={2}><FontAwesomeIcon icon={faFileLines} /></Col>
                <Col>{t("dashboard.menu.preApproval")}</Col>
              </Row>
            </Link>
            <Link to="/dashboard/fullapproval" 
                style={!hasPreapprovalResult ? disabledLinkStyles : linkStyles}>
              <Row>
                <Col xs={2}><FontAwesomeIcon icon={faFileContract} /></Col>
                <Col>{t("dashboard.menu.fullApproval")}</Col>
              </Row>
            </Link>
            <Link to="/dashboard/house/search" 
                style={!hasPreapprovalResult ? disabledLinkStyles : linkStyles}>
              <Row>
                <Col xs={2}><FontAwesomeIcon icon={faMagnifyingGlass} /></Col>
                <Col>{t("dashboard.menu.searchHouse")}</Col>
              </Row>
            </Link>
            <Link to="/dashboard/house/submit"
                style={!hasPreapprovalResult ? disabledLinkStyles : linkStyles}>
              <Row>
                <Col xs={2}><FontAwesomeIcon icon={faFileUpload} /></Col>
                <Col>{t("dashboard.menu.submitHouse")}</Col>
              </Row>
            </Link>

            <Divider></Divider>
            
            <Link to="/dashboard/help" 
                style={linkStyles}>
              <Row>
                <Col xs={2}><FontAwesomeIcon icon={faQuestion} /></Col>
                <Col>{t("dashboard.menu.questions")}</Col>
              </Row>
            </Link>
            <Link to="/dashboard/profile" 
                style={linkStyles}>
              <Row>
                <Col xs={2}><FontAwesomeIcon icon={faGear} /></Col>
                <Col>{t("dashboard.menu.profile")}</Col>
              </Row>                            
            </Link>
          </Nav>
        </Card.Body>
    </Card>
            
  );
}

export default Menu;
