import React from 'react';
import { Container } from 'react-bootstrap';

const CenteredContainer = ({ children }) => {
  return (
    <Container fluid className="d-flex justify-content-center align-items-center vh-100">
        {children}      
    </Container>
  );
};

export default CenteredContainer;