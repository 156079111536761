import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../colors';

import { setLanguage } from '../../reducers/languageSlice';
import { Button } from 'react-bootstrap';


function LanguageToggle() {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language);
  const { i18n, t } = useTranslation();

  React.useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleToggle = () => {
    if (language === 'en') {
      dispatch(setLanguage('fr'));
    } else {
      dispatch(setLanguage('en'));
    }
  };

  return (
    <Button 
        onClick={handleToggle}
        style={{ 
            backgroundColor: colors.orangyPink, 
            borderColor: colors.orangyPink
        }}>
      {t("common.languageToggleButton")}
    </Button>
  );
}

export default LanguageToggle;
