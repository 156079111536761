import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import LandingButton from './LandingButton';

import moment from 'moment';

import { useTranslation } from "react-i18next";

const Landing = () => {
  const { t } = useTranslation();

  const formattedDate = moment().format('MMMM Do, YYYY');
  const firstName = useSelector((state) => state.user.firstName);
  const hasPreapprovalResult = useSelector((state) => state.user.hasPreapprovalResult);

  const approvalButtonDisabled = !hasPreapprovalResult;
  const searchAndBuyButtonDisabled = true // TODO: more logic
  const moveInButtonDisabled = true  // TODO: more logic
  const becomeOwnerButtonDisabled = true // TODO: more logic

  const preapprovalColoredImageSource = require("assets/img/dashboard/preapproval_colored.png")

  const approvalGrayImageSource = require("assets/img/dashboard/approval_gray.png")
  const approvalColoredImageSource = require("assets/img/dashboard/approval_gray.png")

  const searchAndBuyGrayImageSource = require("assets/img/dashboard/searchandbuy_gray.png")
  // TODO: searchAndBuyColoredImageSource

  const moveinGrayImageSource = require("assets/img/dashboard/movein_gray.png")
  // TODO: moveinColoredImageSource
  const becomeOwnerGrayImageSource = require("assets/img/dashboard/becomeowner_gray.png")
  // TODO: becomeOwnerColoredImageSource

  return (
    <Card>
      <Card.Header>{t("dashboard.header")}</Card.Header>
      <Card.Body>
        <Card.Title>{formattedDate}</Card.Title>
        <Card.Text>
          {t("dashboard.hello")}{" "}{firstName}!
        </Card.Text>
        <Row>
          <Col>
            <LandingButton
              source={preapprovalColoredImageSource}
              alt={"pre-approval"}
              text={t("dashboard.buttons.preApproval")}
              linkTo={"/dashboard/preapproval"}
              isDisabled={false}
              hasPreapprovalResult={hasPreapprovalResult} />
          </Col>
          <Col>
            <LandingButton
              source={approvalButtonDisabled ? approvalGrayImageSource : approvalColoredImageSource}
              alt={"approval"}
              text={t("dashboard.buttons.fullApproval")}
              linkTo={"/dashboard/fullapproval"}
              isDisabled={approvalButtonDisabled} />
          </Col>
          <Col>
            <LandingButton
              source={searchAndBuyGrayImageSource}
              // source={searchAndBuyButtonDisabled ? searchAndBuyGrayImageSource : searchAndBuyColoredImageSource} 
              alt={"search & buy"}
              text={t("dashboard.buttons.searchAndBuy")}
              linkTo={"/dashboard/house/search"}
              isDisabled={searchAndBuyButtonDisabled} />
          </Col>
          <Col>
            <LandingButton
              source={moveinGrayImageSource}
              // source={moveInButtonDisabled ? moveinGrayImageSource : moveinColoredImageSource} 
              alt={"move-in"}
              text={t("dashboard.buttons.moveIn")}
              linkTo={"/dashboard/house/submit"}
              isDisabled={moveInButtonDisabled} />
          </Col>
          <Col>
            <LandingButton
              source={becomeOwnerGrayImageSource}
              // source={becomeOwnerButtonDisabled ? becomeOwnerGrayImageSource : becomeOwnerColoredImageSource} 
              alt={"become owner"}
              text={t("dashboard.buttons.becomeOwner")}
              linkTo={"/dashboard/preapproval"}
              isDisabled={becomeOwnerButtonDisabled} />
          </Col>
        </Row>
        <Card.Text>
          {t("dashboard.footerMessage")}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default Landing;
