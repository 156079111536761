import { configureStore } from '@reduxjs/toolkit';
import languageSlice from 'reducers/languageSlice';
import registrationSlice from 'reducers/registrationSlice';
import loginSlice from 'reducers/loginSlice';
import forgotPasswordSlice from 'reducers/forgotPasswordSlice';
import resetPasswordSlice from 'reducers/resetPasswordSlice';
import userSlice from 'reducers/userSlice';
import preapprovalApplicationSlice from 'reducers/preapprovalApplicationSlice';
import preapprovalResultsSlice from 'reducers/preapprovalResultsSlice';
import extraDataSlice from 'reducers/extraDataSlice';
const rootReducer = {
  language: languageSlice,
  registration: registrationSlice,
  login: loginSlice,
  forgotPassword: forgotPasswordSlice,
  resetPassword: resetPasswordSlice,
  user: userSlice,
  preapprovalApplication: preapprovalApplicationSlice,
  preapprovalResults: preapprovalResultsSlice,
  extraData: extraDataSlice,
  // other reducers can go here...
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;