import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    eligibility: false,
    ineligibilityReasons: [],
    budget: 0.0,
    monthlyPayments: 0.0,
    monthlyPaymentDownPaymentCreditAccumulated: 0.0,
    threeYearPurchasePrice: 0.0,
    threeYearCreditAccumulated: 0.0,
    createdAtString: ""
}

const preapprovalResultsSlice = createSlice({
    name: 'preapprovalResults',
    initialState: initialState,
    reducers: {
        setEligibility(state, action)  {
            state.eligibility = action.payload.eligibility;
        },
        setIneligibilityReasons(state, action)  {
            state.ineligibilityReasons = action.payload.ineligibilityReasons;
        },
        setBudget(state, action) {
            state.budget = action.payload.budget;
        },
        setMonthlyPayments(state, action) {
            state.monthlyPayments = action.payload.monthlyPayments;
        },
        setMonthlyPaymentDownPaymentCreditAccumulated(state, action) {
            state.monthlyPaymentDownPaymentCreditAccumulated = action.payload.monthlyPaymentDownPaymentCreditAccumulated;
        },
        setThreeYearPurchasePrice(state, action) {
            state.threeYearPurchasePrice = action.payload.threeYearPurchasePrice;
        },
        setThreeYearCreditAccumulated(state, action) {
            state.threeYearCreditAccumulated = action.payload.threeYearCreditAccumulated;
        },
        setCreatedAtString(state, action) {
            state.createdAtString = action.payload.createdAtString;
        },
    }
})

export const {
    setEligibility,
    setIneligibilityReasons,
    setBudget,
    setMonthlyPayments,
    setMonthlyPaymentDownPaymentCreditAccumulated,
    setThreeYearPurchasePrice,
    setThreeYearCreditAccumulated,
    setCreatedAtString
} = preapprovalResultsSlice.actions;
export default preapprovalResultsSlice.reducer;
