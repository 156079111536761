import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ApplicantTabCommonContent from './applicantTabCommonContent';

import { setPreapprovalApplicationField } from 'reducers/preapprovalApplicationSlice';


const ApplicantTabContent = () => {
  // const { t } = useTranslation();

  const dispatch = useDispatch();

  // obtain preapproval form information from redux state
  const applicantFirstName = useSelector((state) => state.preapprovalApplication.applicantFirstName);
  const applicantLastName = useSelector((state) => state.preapprovalApplication.applicantLastName);
  const applicantEmail = useSelector((state) => state.preapprovalApplication.applicantEmail);
  const applicantMobilePhoneNumber = useSelector((state) => state.preapprovalApplication.applicantMobilePhoneNumber);

  // obtain exising user information from redux state
  const existingUserFirstName = useSelector((state) => state.user.firstName);
  const existingUserLastName = useSelector((state) => state.user.lastName);
  const existingUserEmail = useSelector((state) => state.user.email);

  const [hasModifiedFirstName, setHasModifiedFirstName] = useState(false);
  const [hasModifiedLastName, setHasModifiedLastName] = useState(false);
  const [hasModifiedEmail, setHasModifiedEmail] = useState(false);
  const autoFillFirstName = (applicantFirstName === "" && !hasModifiedFirstName) ? existingUserFirstName : applicantFirstName;
  const autoFillLastName = (applicantLastName === "" && !hasModifiedLastName) ? existingUserLastName : applicantLastName;
  const autoFillEmail = (applicantEmail === "" && !hasModifiedEmail) ? existingUserEmail : applicantEmail;

  // preset the form data upon component mount
  useEffect(() => {
    if (!hasModifiedFirstName) {
      dispatch(setPreapprovalApplicationField({
        fieldName: "applicantFirstName",
        fieldValue: autoFillFirstName
      }))
    }
    if (!hasModifiedLastName) {
      dispatch(setPreapprovalApplicationField({
        fieldName: "applicantLastName",
        fieldValue: autoFillLastName
      }))
    }
    if (!hasModifiedEmail) {
      dispatch(setPreapprovalApplicationField({
        fieldName: "applicantEmail",
        fieldValue: autoFillEmail
      }))
    }
  }, [
    dispatch,
    autoFillFirstName,
    autoFillLastName,
    autoFillEmail,

    hasModifiedFirstName,
    hasModifiedLastName,
    hasModifiedEmail
  ]);

  return (
    <ApplicantTabCommonContent
      applicantFirstName={applicantFirstName}
      applicantLastName={applicantLastName}
      applicantEmail={applicantEmail}
      applicantMobilePhoneNumber={applicantMobilePhoneNumber}

      setApplicantFirstNameFunction={(e) => {
        const newValue = e.target.value;
        // Only update the value if it contains only alphabetic characters or a dash
        if (/^[a-zA-Z-]*$/.test(newValue)) {
          setHasModifiedFirstName(true)
          dispatch(setPreapprovalApplicationField({
            fieldName: "applicantFirstName",
            fieldValue: e.target.value
          }))
        }
      }}
      setApplicantLastNameFunction={(e) => {
        const newValue = e.target.value;
        // Only update the value if it contains only alphabetic characters or a dash
        if (/^[a-zA-Z-]*$/.test(newValue)) {
          setHasModifiedLastName(true)
          dispatch(setPreapprovalApplicationField({
            fieldName: "applicantLastName",
            fieldValue: e.target.value
          }))
        }
      }}
      setApplicantEmailFunction={(e) => {
        setHasModifiedEmail(true)
        dispatch(setPreapprovalApplicationField({
          fieldName: "applicantEmail",
          fieldValue: e.target.value
        }))
      }
      }
      setApplicantMobilePhoneNumberFunction={(e) => {
        dispatch(setPreapprovalApplicationField({
          fieldName: "applicantMobilePhoneNumber",
          fieldValue: e.target.value
        }))
      }}

      modifyFirstNameFunction={setHasModifiedFirstName}
      modifyLastNameFunction={setHasModifiedLastName}
      modifyEmailFunction={setHasModifiedEmail}

      displaySideText={true}
    />
  );
}

export default ApplicantTabContent;
