import { createSlice } from '@reduxjs/toolkit';

import {
  doesTokenExistLocallyAndIsValidIfSo,
  getEmailClaimFromLocalStorageToken,
  isNotNullAndNotUndefined,
  convertBooleanToNoYesValueLabel
} from 'components/utils';

const emptyState = {
  applicantFirstName: "",
  applicantLastName: "",
  applicantEmail: "",

  applicantMobilePhoneNumber: "",

  applicantGrossAnnualRevenue: null,
  applicantExpenses: null,
  applicantApproximateCreditScore: {},
  applicantEverFiledBankruptcy: false,
  applicantDischargedDateUTC: null,

  hasSecondApplicant: false,

  coApplicantFirstName: "",
  coApplicantLastName: "",
  coApplicantEmail: "",

  coApplicantMobilePhoneNumber: "",

  coApplicantGrossAnnualRevenue: null,
  coApplicantExpenses: null,
  coApplicantApproximateCreditScore: {},
  coApplicantEverFiledBankruptcy: false,
  coApplicantDischargedDateUTC: null,

  downPayment: null,
  initialPayment: 0.0,

  regionOfInterest: "",
  citiesOfInterest: [],
  homeTypesOfInterest: [],
  realtor: {},

  acceptsTermsOfServiceAndPrivacyPolicy: false,
  // ...

}

var initialState = emptyState;

var userEmailPreapprovalApplicationKey = "";
if (doesTokenExistLocallyAndIsValidIfSo()) {
  var userEmail = getEmailClaimFromLocalStorageToken()
  userEmailPreapprovalApplicationKey = userEmail + '.preapprovalApplication'

  if (localStorage.getItem(userEmailPreapprovalApplicationKey)) {
    initialState = JSON.parse(localStorage.getItem(userEmailPreapprovalApplicationKey))
  }
}


const preapprovalApplicationSlice = createSlice({
  name: 'preapprovalApplication',
  initialState: initialState,
  reducers: {
    setFieldToEmptyState:(state, action) => {
      const fieldName = action.payload.fieldName;
      state[fieldName] = emptyState[fieldName];
    },
    setAllPreapprovalApplicationFields: (state, action) => {
      state.applicantFirstName = action.payload.applicantFirstName
      state.applicantLastName = action.payload.applicantLastName
      state.applicantEmail = action.payload.applicantEmail

      state.applicantMobilePhoneNumber = action.payload.applicantMobilePhoneNumber

      state.applicantGrossAnnualRevenue = action.payload.applicantGrossAnnualRevenue
      state.applicantExpenses = action.payload.applicantExpenses
      state.applicantApproximateCreditScore = action.payload.applicantApproximateCreditScore
      state.applicantEverFiledBankruptcy = convertBooleanToNoYesValueLabel(action.payload.applicantEverFiledBankruptcy)
      state.applicantDischargedDateUTC = action.payload.applicantDischargedDateUTC

      state.hasSecondApplicant = (action.payload.coApplicantFirstName !== "")

      state.coApplicantFirstName = action.payload.coApplicantFirstName
      state.coApplicantLastName = action.payload.coApplicantLastName
      state.coApplicantEmail = action.payload.coApplicantEmail

      state.coApplicantMobilePhoneNumber = action.payload.coApplicantMobilePhoneNumber

      state.coApplicantGrossAnnualRevenue = action.payload.coApplicantGrossAnnualRevenue
      state.coApplicantExpenses = action.payload.coApplicantExpenses
      state.coApplicantApproximateCreditScore = action.payload.coApplicantApproximateCreditScore
      state.coApplicantEverFiledBankruptcy = convertBooleanToNoYesValueLabel(action.payload.coApplicantEverFiledBankruptcy)
      state.coApplicantDischargedDateUTC = action.payload.coApplicantDischargedDateUTC

      state.downPayment = action.payload.downPayment
      state.initialPayment = action.payload.initialPayment

      state.regionOfInterest = action.payload.regionOfInterest
      state.citiesOfInterest = action.payload.citiesOfInterest
      state.homeTypesOfInterest = action.payload.homeTypesOfInterest.flatMap(x => [{ value: x, label: x }])
      state.realtor = action.payload.realtor
    },
    setPreapprovalApplicationField: (state, action) => {
      const fieldName = action.payload.fieldName;
      const fieldValue = action.payload.fieldValue;
      state[fieldName] = fieldValue;

      var userEmail = getEmailClaimFromLocalStorageToken()
      if (isNotNullAndNotUndefined(userEmail) && userEmail !== "") {
        userEmailPreapprovalApplicationKey = userEmail + '.preapprovalApplication'
        localStorage.setItem(userEmailPreapprovalApplicationKey, JSON.stringify(state));
      }
    },
    resetCoApplicantFields: (state) => {
      state.coApplicantFirstName = emptyState.coApplicantFirstName
      state.coApplicantLastName = emptyState.coApplicantLastName
      state.coApplicantEmail = emptyState.coApplicantEmail

      state.coApplicantMobilePhoneNumber = emptyState.coApplicantMobilePhoneNumber

      state.coApplicantGrossAnnualRevenue = emptyState.coApplicantGrossAnnualRevenue
      state.coApplicantExpenses = emptyState.coApplicantExpenses
      state.coApplicantApproximateCreditScore = emptyState.coApplicantApproximateCreditScore
      state.coApplicantEverFiledBankruptcy = emptyState.coApplicantEverFiledBankruptcy
      state.coApplicantDischargedDateUTC = emptyState.coApplicantDischargedDateUTC

      return state
    },
    resetPreapprovalApplicationState: (state) => {
      state = emptyState

      var userEmail = getEmailClaimFromLocalStorageToken()
      if (isNotNullAndNotUndefined(userEmail) && userEmail !== "") {
        userEmailPreapprovalApplicationKey = userEmail + '.preapprovalApplication'
        localStorage.removeItem(userEmailPreapprovalApplicationKey);
      }
      return state;
    }
  }
})

export const {
  setFieldToEmptyState,
  setAllPreapprovalApplicationFields,
  setPreapprovalApplicationField,
  resetCoApplicantFields,
  resetPreapprovalApplicationState
} = preapprovalApplicationSlice.actions;

export default preapprovalApplicationSlice.reducer;
