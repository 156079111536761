import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { 
    setEmail,
    resetForgotPasswordState
} from '../../reducers/forgotPasswordSlice';


import { Link } from 'react-router-dom';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import API_URL from 'config';
import colors from '../../colors';

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const email = useSelector((state) => state.forgotPassword.email);

  const handleEmailChange = (event) => {
    dispatch(setEmail({ email: event.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // prepare data
    var data = { 
        email: email,
    };

    // POST data
    var forgotPasswordPostUrl = `${API_URL}/api/user/forgot-password`
    window.fetch(forgotPasswordPostUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // Convert the Javascript object to a JSON string
    })
    .then(request => request.json())
    .then((json) => {
        console.debug(json);
        if (json.status === 200){
            toast.success(`Submission received. You should receive an email at ${email} to reset your password`, {
                theme: 'colored'
            });

            // reset login and registration states
            dispatch(resetForgotPasswordState())

            // go to dashboard
            navigate("/login");

        } else {
            console.error(json)
            toast.error(`Something unexpected happened trying to login as ${email}`, {
                theme: 'colored'
            });
        }
    })
    .catch(error => {
        console.error(error)
        toast.error(`Failed to submit password reset for ${email}`, {
        theme: 'colored'
        });
    });
  };

  return (
    <Card>
        <Card.Header style={{ textAlign: "center"}}>
            <h1>
            {t('forgotPassword.title')}
            </h1>
        </Card.Header>
        <Card.Body style={{ position: "relative" }}>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>{t('common.emailLabel')}</Form.Label>
                    <Form.Control 
                    type="email" 
                    placeholder={t('common.emailLabel')}
                    name="email" 
                    value={email} 
                    onChange={handleEmailChange} 
                    />
                </Form.Group>


                <Form.Group className="mb-4">
                    <Button
                    style={{ 
                        backgroundColor: colors.orangyPink, 
                        borderColor: colors.orangyPink
                    }}
                    className="w-100"
                    type="submit"
                    disabled={ !email }
                    >
                    {t('common.submitButton')}
                    </Button>
                </Form.Group>
            </Form>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <span>
                    {t('forgotPassword.rememberedPasswordMessage')} {t('login.clickMessage')} <Link to="/login">{t('login.hereMessage')}</Link>
                    </span>
                </Col>
            </Row>
        </Card.Body>
    </Card>
  );
}

export default ForgotPasswordForm;
