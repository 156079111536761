import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { resetLoginState } from "../../reducers/loginSlice";
import { 
    setRegistration,
    resetRegistrationState
} from "../../reducers/registrationSlice";

import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Button, Form, Row, Col } from "react-bootstrap";
import Divider from "components/common/Divider";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import colors from "../../colors";
import API_URL from "config";

function RegistrationForm(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const firstName = useSelector((state) => state.registration.firstName);
    const lastName = useSelector((state) => state.registration.lastName);
    const email = useSelector((state) => state.registration.email);
    const password = useSelector((state) => state.registration.password);
    const confirmPassword = useSelector((state) => state.registration.confirmPassword);
    const isAccepted = useSelector((state) => state.registration.isAccepted);

    const [errorMsg, setErrorMsg] = useState(""); // local error state

    // Handler
    const handleSubmit = e => {
        e.preventDefault();

        // prepare data
        var data = { 
            firstName: firstName, 
            lastName: lastName,
            email: email,
            password: password
        };

        // POST data
        var userPostUrl = `${API_URL}/api/user`
        window.fetch(userPostUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data), // Convert the Javascript object to a JSON string
        })
        .then(response => response.json())
        .then(json => {
            console.debug(json);
            if (json.status === 201){
                toast.success(`Successfully registered as ${firstName} ${lastName}`, {
                    theme: "colored"
                });
                
                // reset login and registration states
                dispatch(resetLoginState())
                dispatch(resetRegistrationState())

                props.setPreRegistrationReceived(true)
            } else {
                console.error(json)
                toast.error(`Something unexpected happened trying to register as ${firstName} ${lastName}`, {
                    theme: "colored"
                });
            }
        })
        .catch(error => {
            console.error(error)
            toast.error(`Failed to register as ${firstName} ${lastName}`, {
            theme: "colored"
            });
        });
    };

    const handleFieldChange = e => {
        dispatch(setRegistration({ 
            fieldName: e.target.name, 
            fieldValue: e.target.value 
        }));

        if (e.target.name === "confirmPassword") {
            if (password !== e.target.value) {
              setErrorMsg("Passwords don't match");
            } else {
              setErrorMsg("");
            }
        }
    };

    return (
        <Card>
            <Card.Header style={{ textAlign: "center"}}>
                <h1>{t("registration.title")}</h1>
            </Card.Header>
            <Card.Body style={{ position: "relative" }}>
                <Form onSubmit={handleSubmit}>
                <Row className="g-2 mb-3">
                <Form.Group className="mb-3">
                    <Form.Label>{t("common.firstName")}</Form.Label>
                    <Form.Control
                    placeholder={t("common.firstName")}
                    value={firstName}
                    name="firstName"
                    onChange={handleFieldChange}
                    type="text"
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>{t("common.lastName")}</Form.Label>
                    <Form.Control
                    placeholder={t("common.lastName")}
                    value={lastName}
                    name="lastName"
                    onChange={handleFieldChange}
                    type="text"
                    />
                </Form.Group>
                </Row>

                <Divider></Divider>

                <Form.Group className="mb-3">
                    <Form.Label>{t("common.emailLabel")}</Form.Label>
                    <Form.Control
                    placeholder={t("common.emailLabel")}
                    value={email}
                    name="email"
                    onChange={handleFieldChange}
                    type="email"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid email address.
                    </Form.Control.Feedback>
                </Form.Group>

                <Row className="g-2 mb-3">
                    <Form.Group as={Col} sm={6}>
                        <Form.Label>{t("common.passwordLabel")}</Form.Label>
                        <Form.Control
                            placeholder={t("common.passwordLabel")}
                            value={password}
                            name="password"
                            onChange={handleFieldChange}
                            type="password"
                            className={errorMsg ? "is-invalid" : ""}
                        />
                    </Form.Group>
                    <Form.Group as={Col} sm={6}>
                        <Form.Label>{t("common.confirmPasswordLabel")}</Form.Label>
                        <Form.Control
                            placeholder={t("common.confirmPasswordLabel")}
                            value={confirmPassword}
                            name="confirmPassword"
                            onChange={handleFieldChange}
                            type="password"
                            className={errorMsg ? "is-invalid" : ""}
                            />
                        <Form.Control.Feedback type="invalid">
                            {errorMsg}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Form.Group className="mb-3">
                    <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
                    <Form.Check.Input
                        type="checkbox"
                        name="isAccepted"
                        checked={isAccepted}
                        onChange={handleFieldChange}
                    />
                    <Form.Check.Label className="form-label">
                        {t("registration.termsAcceptMessagePart1")}{" "}
                        <Link to="#!">{t("registration.termsAcceptMessagePart2")}</Link>{" "}
                        {t("registration.termsAcceptMessagePart3")}{" "}
                        <Link to="#!">{t("registration.termsAcceptMessagePart4")}</Link>
                    </Form.Check.Label>
                    </Form.Check>
                </Form.Group>

                <Form.Group className="mb-4">
                    <Button
                    style={{ 
                        backgroundColor: colors.orangyPink, 
                        borderColor: colors.orangyPink
                    }}
                    className="w-100"
                    type="submit"
                    disabled={
                        !firstName ||
                        !lastName ||
                        !email ||
                        !password ||
                        !confirmPassword ||
                        !(password === confirmPassword) ||
                        !isAccepted
                    }
                    >
                    {t("common.submitButton")}
                    </Button>
                </Form.Group>
            </Form>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <span>
                        {t("registration.alreadyRegisteredMessage")} <Link to="/login">{t("common.loginWordCapitalized")}</Link>
                    </span>
                </Col>
            </Row>
            </Card.Body>
        </Card>
    );
    }

RegistrationForm.propTypes = {
    hasLabel: PropTypes.bool
};

export default RegistrationForm;
