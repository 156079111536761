import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Form, Card, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// import Divider from 'components/common/Divider';

import { useTranslation } from "react-i18next";

import { setPreapprovalApplicationField } from 'reducers/preapprovalApplicationSlice';
import colors from 'colors';


const PropertyTabContent = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    // obtain predefined list of verified realtor, region, and city names
    const realtorOptions = useSelector((state) => state.extraData.realtors)
        .flatMap(x => [{ "value": x.id, "label": x.name }]);
    const regionNameOptions = useSelector((state) => state.extraData.regions)
        .flatMap(x => [{ "value": x.id, "label": x.name }]);
    const cityNameOptions = useSelector((state) => state.extraData.cities)
        .flatMap(x => [{ "value": x.id, "label": x.name }]);

    // obtain preapproval form information from redux state
    const regionOfInterest = useSelector((state) =>
        state.preapprovalApplication.regionOfInterest);
    const citiesOfInterest = useSelector((state) =>
        state.preapprovalApplication.citiesOfInterest);
    const homeTypesOfInterest = useSelector((state) =>
        state.preapprovalApplication.homeTypesOfInterest);
    const downPayment = useSelector((state) =>
        state.preapprovalApplication.downPayment);


    // const homePurchaseTimeline = useSelector((state) =>
    //     state.preapprovalApplication.homePurchaseTimeline);
    const realtor = useSelector((state) =>
        state.preapprovalApplication.realtor);
    // const realtorInList = useSelector((state) =>
    //     state.preapprovalApplication.realtorInList);
    const acceptsTermsOfServiceAndPrivacyPolicy = useSelector((state) =>
        state.preapprovalApplication.acceptsTermsOfServiceAndPrivacyPolicy);
    // TODO: rest of the requirements ...

    const handleRegionOfInterestChange = e => {
        dispatch(setPreapprovalApplicationField({
            fieldName: "regionOfInterest",
            fieldValue: e
        }))
    }
    const handleCommonDelineatedCitiesOfInterestChange = e => {
        dispatch(setPreapprovalApplicationField({
            fieldName: "citiesOfInterest",
            fieldValue: e
        }))
    }
    const handleHomeTypesOfInterestChange = e => {
        dispatch(setPreapprovalApplicationField({
            fieldName: "homeTypesOfInterest",
            fieldValue: e
        }))
    }
    const handleDownpaymentChange = e => {
        var value = e.target.value
        // Allow the update if the new value is a number
        if (value === '' || /^\d+$/.test(value)) {
            value = Number(value).toString() // remove leading 0's if any
            dispatch(setPreapprovalApplicationField({
                fieldName: "downPayment",
                fieldValue: value
            }))
        }
    }
    // const handleHomePurchaseTimelineChange = e => {
    //     dispatch(setHomePurchaseTimeline(e))
    // }
    const handleRealtorChange = e => {
        dispatch(setPreapprovalApplicationField({
            fieldName: "realtor",
            fieldValue: e
        }))
    }
    // const handlesetRealtorInListChange = e => {
    //     dispatch(setRealtorInList(e))
    // }
    const handleAgreesTermsOfUseAndPrivacyPolicy = e => {
        dispatch(setPreapprovalApplicationField({
            fieldName: "acceptsTermsOfServiceAndPrivacyPolicy",
            fieldValue: e.target.checked
        }))
    }

    return (
        <Row>
            <Col>
                <Form>
                    <Row className="mb-3">
                        <Form.Label>DESIRED HOUSE : </Form.Label>
                        <Col>
                            <Form.Group controlId="regionOfInterest">
                                <Form.Label>Region Of Interest <span style={{ color: colors.orangyPink }}>*</span></Form.Label>
                                <Select
                                    name="regionOfInterest"
                                    placeholder="Select the region where you wish to live"
                                    value={regionOfInterest}
                                    // onInputChange={}
                                    onChange={handleRegionOfInterestChange}
                                    options={regionNameOptions}
                                />
                            </Form.Group>
                            <Form.Group controlId="citiesOfInterest">
                                <Form.Label>Cities Of Interest</Form.Label>
                                <CreatableSelect
                                    isMulti
                                    name="citiesOfInterest"
                                    placeholder="Select one or multiple interesting cities"
                                    value={citiesOfInterest}
                                    // onInputChange={}
                                    onChange={handleCommonDelineatedCitiesOfInterestChange}
                                    options={cityNameOptions}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Card.Text style={{ color: colors.darkBlue }}>
                                <b>LOCATION, LOCATION, LOCATION:</b>
                                <br />
                                <span>
                                    The location is an important factor in well-being. To ensure true accessibility to ou program across the map, we must convey your interests to our investors. While selecting the right region is vital, gaining insight into promising regions or cities is paramount for regional development.
                                </span>
                            </Card.Text>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Group controlId="homeTypesOfInterest">
                                <Form.Label>Home Types</Form.Label>
                                {/* TODO: obtain options dynamically? */}
                                <Select
                                    isMulti
                                    name="homeTypesOfInterest"
                                    placeholder="Select one or multiple interesting types of home"
                                    options={[
                                        { value: 'Home Type A', label: 'Home Type A' },
                                        { value: 'Home Type B', label: 'Home Type B' },
                                        { value: 'Home Type C', label: 'Home Type C' },
                                        // add more options as needed
                                    ]}
                                    value={homeTypesOfInterest}
                                    onChange={handleHomeTypesOfInterestChange}
                                />
                            </Form.Group>
                            {/* <Form.Group controlId="homePurchaseTimeline">
                                <Form.Label>Purchase Timeline <span style={{ color: colors.orangyPink }}>*</span></Form.Label>
                                <Select
                                    placeholder="When would you like to buy a home?"
                                    name="homePurchaseTimeline"
                                    options={[
                                        { value: '0', label: 'Time A' },
                                        { value: '1', label: 'Time B' },
                                        { value: '2', label: 'Time C' },
                                        // add more options as needed
                                    ]}
                                    value={homePurchaseTimeline}
                                    onChange={handleHomePurchaseTimelineChange}
                                />
                            </Form.Group> */}
                            <Form.Group controlId="downPayment">
                                <Form.Label>Downpayment <span style={{ color: colors.orangyPink }}>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="How much of a down payment do you have?"
                                    name="downPayment"
                                    value={downPayment}
                                    onChange={handleDownpaymentChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Card.Text style={{ color: colors.darkBlue }}>
                                <b>TIME IS OF THE ESSENCE :</b>
                                <br />
                                <span>
                                    Time is your trusty ally when it comes to buying a house. The sooner you lock in that price, the sooner you're building a solid foundation for your future investment. Think of it as a race against uncertainty - the sooner you start, the quicker you secure your dream home and your financial future.
                                </span>
                            </Card.Text>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Group controlId="realtor">
                                <Form.Label>Realtor</Form.Label>
                                <CreatableSelect
                                    name="realtor"
                                    placeholder="Realtor"
                                    value={realtor}
                                    // onInputChange={}
                                    onChange={handleRealtorChange}
                                    options={realtorOptions}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Card.Text style={{ color: colors.darkBlue }}>
                                <b>REALTORS :</b>
                                <br />
                                <span>
                                    Realtors are important to us but very much to you too! They have to offer on top of our tools, education material and advisors, a security baseline, organise visits, and advise about neighborhood. But that's not all - very soon they will provide you with insights helping you take the best-informed decisions. Wait to see!
                                </span>
                            </Card.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
                                    <Form.Check.Input
                                        type="checkbox"
                                        name="acceptsTermsOfServiceAndPrivacyPolicy"
                                        checked={acceptsTermsOfServiceAndPrivacyPolicy}
                                        onChange={handleAgreesTermsOfUseAndPrivacyPolicy}
                                    />
                                    <Form.Check.Label className="form-label">
                                        {t("registration.termsAcceptMessagePart1")}{" "}
                                        <Link to="#!">{t("registration.termsAcceptMessagePart2")}</Link>{" "}
                                        {t("registration.termsAcceptMessagePart3")}{" "}
                                        <Link to="#!">{t("registration.termsAcceptMessagePart4")}</Link>
                                    </Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
}

export default PropertyTabContent;
