import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: "",
  password: ""
}

const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    setEmail(state, action) {
      state.email = action.payload.email;
    },
    setPassword(state, action) {
      state.password = action.payload.password;
    },
    resetLoginState: () => initialState,
  }
})

export const { 
  setEmail, 
  setPassword,
  resetLoginState
} = loginSlice.actions;
export default loginSlice.reducer;
