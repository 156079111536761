import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: "",
}

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: initialState,
  reducers: {
    setEmail(state, action) {
      state.email = action.payload.email;
    },
    resetForgotPasswordState: () => initialState,
  }
})

export const { 
  setEmail, 
  resetForgotPasswordState
} = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
