import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navbar, Nav, Container, Button, Row, Col, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import LanguageToggle from '../../components/language';
import Landing from '../../components/dashboard/Landing';
import Menu from 'components/dashboard/Menu';
import PreApproval from 'components/dashboard/PreApproval';
import UnderConstruction from 'components/common/UnderConstruction';

import {
  doesTokenExistLocallyAndIsValidIfSo,
  getTokenFromLocalStorage,
  removeTokenFromLocalStorage
} from 'components/utils';

import logo from '../../assets/img/logos/homr_white.png';
import colors from '../../colors';

import { resetPreapprovalApplicationState } from 'reducers/preapprovalApplicationSlice';
import {
  setUserInfoState,
  resetUserInfoState
} from '../../reducers/userSlice';

import {
  setExtraData
} from '../../reducers/extraDataSlice';
import API_URL from 'config';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userState = useSelector((state) => state.user);

  if (!doesTokenExistLocallyAndIsValidIfSo()) {
    navigate('/login');
  }

  // logout handling
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const handleCloseLogoutModal = () => setShowLogoutModal(false);
  const handleShowLogoutModal = () => {
    // - warn user of potential lost changes to an ongoing pre-approval 
    // application if a pre-approval result has already been submitted
    if (userState.hasPreapprovalResult) {
      setShowLogoutModal(true);
      return;
    }
    // - go ahead and logout without warning
    handleLogout();
  };

  var existingToken = getTokenFromLocalStorage()

  // else, user is logged in
  var getUserInfoUrl = `${API_URL}/api/user/info`
  window.fetch(getUserInfoUrl, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + existingToken
    }
  })
    .then(response => response.json())
    .then(json => {
      if (json.status === 200) {
        console.debug("Got user: ", json);

        // set local preapproval state
        dispatch(setUserInfoState({
          firstName: json.firstName,
          lastName: json.lastName,
          email: json.email,

          hasPreapprovalResult: json.hasPreapprovalResult
        }));
      } else {
        console.error(json)
        toast.error(`Something unexpected happened trying to obtain logged in user info`, {
          theme: 'colored'
        });
      }
    })
    .catch(error => {
      console.error(error)
      toast.error(`Failed to obtain logged in user info`, {
        theme: 'colored'
      });
    });

  // get extra data
  var getExtraDataUrl = `${API_URL}/api/data`
  window.fetch(getExtraDataUrl, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + existingToken
    }
  })
    .then(response => response.json())
    .then(json => {
      if (json.status === 200) {
        console.debug("Got extra data: ", json);

        // set local preapproval state
        dispatch(setExtraData({
          fieldName: "realtors",
          fieldValue: json.realtors
        }));
        dispatch(setExtraData({
          fieldName: "regions",
          fieldValue: json.regions
        }));
        dispatch(setExtraData({
          fieldName: "cities",
          fieldValue: json.cities
        }));
        dispatch(setExtraData({
          fieldName: "creditScores",
          fieldValue: json.creditScores
        }));
      } else {
        console.error(json)
      }
    })
    .catch(error => {
      console.error(error)
    });

  const handleLogout = async () => {
    // cascade delete local redux states
    // - preapproval application if a preapproval result has already been submitted
    if (userState.hasPreapprovalResult) {
      dispatch(resetPreapprovalApplicationState())
    }
    // - user state
    dispatch(resetUserInfoState())

    // clear jwt token from local storage
    removeTokenFromLocalStorage()

    // close the modal
    handleCloseLogoutModal();

    // go to login page
    navigate("/login");

    console.debug("Logged out");
  }

  return (
    <>
      <Navbar expand="lg" style={{
        backgroundColor: colors.darkBlue
      }}>
        <Navbar.Brand href="/">
          <img className="me-2" src={logo} alt="Logo" width={"80%"} />
        </Navbar.Brand>
        <Nav className="ml-auto">
          <Row>
            <Col>
              <LanguageToggle />
            </Col>
            <Col>
              <Button
                style={{
                  backgroundColor: colors.orangyPink,
                  borderColor: colors.orangyPink
                }}
                onClick={handleShowLogoutModal}>Logout</Button>
            </Col>
          </Row>
        </Nav>
      </Navbar>
      <Container fluid>
        <Row>
          <Col md={3}>
            <Menu />
          </Col>
          <Col md={9}>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/preapproval" element={<PreApproval />} />
              <Route path="/fullapproval" element={<UnderConstruction />} />
              <Route path="/house/search" element={<UnderConstruction />} />
              <Route path="/house/submit" element={<UnderConstruction />} />
              <Route path="/house/submit" element={<UnderConstruction />} />
              <Route path="/help" element={<UnderConstruction />} />
              <Route path="/profile" element={<UnderConstruction />} />
            </Routes>
          </Col>
        </Row>
        <Modal show={showLogoutModal} onHide={handleCloseLogoutModal}>
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to logout? Any unsaved modifications to your pre-approval application will be lost.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseLogoutModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleLogout}>
              Yes, Logout
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default Dashboard;
