import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  newPassword: "",
  newPasswordConfirmation: "",
}

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState: initialState,
  reducers: {
    setNewPassword(state, action) {
      state.newPassword = action.payload.newPassword;
    },
    setNewPasswordConfirmation(state, action) {
      state.newPasswordConfirmation = action.payload.newPasswordConfirmation;
    },
    resetResetPasswordState: () => initialState,
  }
})

export const {
  setNewPassword,
  setNewPasswordConfirmation,
  resetResetPasswordState
} = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
