import jwtDecode from 'jwt-decode';

export const isTokenExpired = (token) => {
    try {
        const decoded = jwtDecode(token);
        return decoded.exp < Date.now() / 1000;
    } catch (err) {
        console.error("Error decoding token: ", err);
        return false;
    }
};

export const doesTokenExistLocallyAndIsValidIfSo = () => {
    var existingToken = getTokenFromLocalStorage();

    if (existingToken === undefined
        || existingToken === null
        || isTokenExpired(existingToken)) {
        return false;
    }
    return true;
};

export const getTokenFromLocalStorage = () => {
    return localStorage.getItem('token');
}
export const removeTokenFromLocalStorage = () => {
    return localStorage.removeItem('token');
}
export const getEmailClaimFromLocalStorageToken = () => {
    var token = getTokenFromLocalStorage();

    // The payload (the middle part) of a JWT is just a base64-encoded JSON string
    const payloadBase64Url = token.split('.')[1];
    const payloadBase64 = payloadBase64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payloadJson = atob(payloadBase64);
    const payload = JSON.parse(payloadJson);

    // Now payload is an object containing your claims
    return payload.email;
}

export const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
}

export const isNotNullAndNotUndefined = (x) => {
    return x !== null && x !== undefined;
}
export const isNotNullAndNotUndefinedAndNotEmpty = (x) => {
    return x !== null && x !== undefined && x !== "";
}
export const isNullOrUndefinedOrEmpty = (x) => {
    return x === null || x === undefined || x === "";
}

export const convertBooleanToNoYesValueLabel = (x) => {
    var label
    if (x === true) {
        label = "Yes"
    } else {
        label = "No"
    }
    return {"value": Number(x), "label": label}
}