import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetching: false,
  // sessionId: "",

  firstName: "",
  lastName: "",
  email: "",

  hasPreapprovalResult: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUserInfoFetchingState: (state, action) => {
      state.isFetching = action.payload.isFetching;
    },
    setUserInfoState: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;

      state.hasPreapprovalResult = action.payload.hasPreapprovalResult;
    },
    updatePreapproval: (state, action) => {
      const fieldName = action.payload.fieldName;
      const fieldValue = action.payload.fieldValue;
      state[fieldName] = fieldValue;
    },
    resetUserInfoState: () => initialState,
  }
})

export const {
  setUserInfoFetchingState,
  setUserInfoState,
  updatePreapproval,
  resetUserInfoState
} = userSlice.actions;

export default userSlice.reducer;
