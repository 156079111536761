import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import API_URL from 'config';
import colors from 'colors';

import { Spinner } from 'react-bootstrap';

import moment from 'moment';


import {
    setEligibility,
    setIneligibilityReasons,
    setBudget,
    setMonthlyPayments,
    setMonthlyPaymentDownPaymentCreditAccumulated,
    setThreeYearPurchasePrice,
    setThreeYearCreditAccumulated,
    setCreatedAtString
} from 'reducers/preapprovalResultsSlice';

import {
    getTokenFromLocalStorage
} from 'components/utils';

import Divider from 'components/common/Divider';

var dateTextStyle = {
    color: colors.darkBlue,
    fontWeight: "bold"
}
var valueTextStyle = {
    color: colors.orangyPink,
    fontWeight: "bold"
}

const ResultsTabContent = ({ activeKey }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);

    const eligibility = useSelector((state) => state.preapprovalResults.eligibility);
    const ineligibilityReasons = useSelector((state) => state.preapprovalResults.ineligibilityReasons);
    const budget = useSelector((state) => state.preapprovalResults.budget);
    const monthlyPayments = useSelector((state) => state.preapprovalResults.monthlyPayments);
    const monthlyPaymentDownPaymentCreditAccumulated = useSelector((state) => state.preapprovalResults.monthlyPaymentDownPaymentCreditAccumulated);
    const threeYearPurchasePrice = useSelector((state) => state.preapprovalResults.threeYearPurchasePrice);
    const threeYearCreditAccumulated = useSelector((state) => state.preapprovalResults.threeYearCreditAccumulated);
    const createdAtString = useSelector((state) => state.preapprovalResults.createdAtString);

    const formattedDate = moment(createdAtString).format('hh:mm:ss, MMMM Do, YYYY');

    useEffect(() => {
        // prevent fetch every tab change
        if (activeKey !== 'fourth') {
            return
        }

        setIsLoading(true)

        // Get preapproval results
        var existingToken = getTokenFromLocalStorage();
        var applicationResultsGetUrl = `${API_URL}/api/preapproval/results`;

        setTimeout(function () {
            window.fetch(applicationResultsGetUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + existingToken
                },
            })
                .then(request => request.json())
                .then((json) => {
                    console.debug(json);
                    if (json.status === 200) {
                        setIsLoading(false)
                        dispatch(setEligibility({
                            eligibility: json.eligibility
                        }))
                        dispatch(setIneligibilityReasons({
                            ineligibilityReasons: json.ineligibilityReasons
                        }))
                        dispatch(setBudget({
                            budget: json.budget
                        }))
                        dispatch(setMonthlyPayments({
                            monthlyPayments: json.monthlyPayments
                        }))
                        dispatch(setMonthlyPaymentDownPaymentCreditAccumulated({
                            monthlyPaymentDownPaymentCreditAccumulated: json.monthlyPaymentDownPaymentCreditAccumulated
                        }))
                        dispatch(setThreeYearPurchasePrice({
                            threeYearPurchasePrice: json.threeYearPurchasePrice
                        }))
                        dispatch(setThreeYearCreditAccumulated({
                            threeYearCreditAccumulated: json.threeYearCreditAccumulated
                        }))
                        dispatch(setCreatedAtString({
                            createdAtString: json.createdAt
                        }))

                        toast.success(`Successfully obtained preapproval results`, {
                            theme: 'colored'
                        });
                    } else {
                        setIsLoading(false)
                        console.error(json)
                        toast.error(`Something unexpected happened trying to obtain preapproval results`, {
                            theme: 'colored'
                        });
                    }
                })
                .catch(error => {
                    setIsLoading(false)
                    console.error(error)
                    toast.error(`Failed to obtain the preapproval results`, {
                        theme: 'colored'
                    });
                });
        }, 1000)
    }, [dispatch, activeKey]);  // Empty dependency array ensures the effect only runs once on mount



    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }
    return (
        <Card style={{ backgroundColor: colors.lightBlue }}>
            <Card.Header>
                <h2>
                    <b>{eligibility ?
                        t('dashboard.preapproval.results.successHeader') :
                        t('dashboard.preapproval.results.failureHeader')}</b>
                </h2>
            </Card.Header>
            <Card.Text style={{ color: colors.darkBlue }}>
                <Row>
                    <Col>
                        <b>{t('dashboard.preapproval.results.submissionDateLabel')}</b>
                    </Col>
                    <Col style={dateTextStyle}>
                        {formattedDate}
                    </Col>
                </Row>
                {eligibility ?
                    <>
                        <Row>
                            <Col>
                                <b>{t('dashboard.preapproval.results.yourBugetLabel')}:</b>
                            </Col>
                            <Col style={valueTextStyle}>
                                ${Number(Math.abs(budget).toFixed(2)).toLocaleString()}
                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col>
                                <b>{t('dashboard.preapproval.results.yourMonthlyPaymentsLabel')}:</b>
                            </Col>
                            <Col style={valueTextStyle}>
                                ${Number(Math.abs(monthlyPayments).toFixed(2)).toLocaleString()}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>{t('dashboard.preapproval.results.downPaymentCreditAccumulatedLabel')}:</b>
                            </Col>
                            <Col style={valueTextStyle}>
                                ${Number(Math.abs(monthlyPaymentDownPaymentCreditAccumulated).toFixed(2)).toLocaleString()}
                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col>
                                <b>{t('dashboard.preapproval.results.threeYearPurchasePriceLabel')}:</b>
                            </Col>
                            <Col style={valueTextStyle}>
                                ${Number(Math.abs(threeYearPurchasePrice).toFixed(2)).toLocaleString()}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <b>{t('dashboard.preapproval.results.threeYearCreditAccumulatedLabel')}:</b>
                            </Col>
                            <Col style={valueTextStyle}>
                                ${Number(Math.abs(threeYearCreditAccumulated).toFixed(2)).toLocaleString()}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>{t('dashboard.preapproval.results.legalDisclaimerMessage')}:</b>
                            </Col>
                        </Row>
                    </> :
                    <>
                        <Divider />

                        <Row>
                            <Col>
                                <b>{t('dashboard.preapproval.results.failureReasonsLabel')}:</b>
                            </Col>
                        </Row>
                        {ineligibilityReasons.map((item, index) => {
                            let displayText = '';

                            switch (item) {
                                case 'CreditScoreTooLow':
                                    displayText = t('dashboard.preapproval.results.creditScoreTooLowMessage');
                                    break;
                                case 'TotalRevenueTooLow':
                                    displayText = t('dashboard.preapproval.results.totalRevenueTooLowMessage');
                                    break;
                                case 'FiledSettlementDateTooRecent':
                                    displayText = t('dashboard.preapproval.results.filedSettlementDateTooRecentMessage');
                                    break;
                                default:
                                    displayText = t('dashboard.preapproval.results.unknownReasonMessage');
                            }

                            return (<Row className="mb-3">

                                <Col>
                                    - {displayText},
                                </Col>
                            </Row>)
                        })}
                    </>
                }
            </Card.Text>

            {eligibility &&
                <>
                    <Card.Header>
                        <h2>
                            <b>{t('dashboard.preapproval.results.yourNextStepsHeader')}</b>
                        </h2>
                    </Card.Header>
                    <Card.Text style={{ color: colors.darkBlue }}>
                        <Row>
                            <Col>
                                <b>{t('dashboard.preapproval.results.approbationLabel')}:</b>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                {t('dashboard.preapproval.results.approbationMessage')}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>{t('dashboard.preapproval.results.researchLabel')}:</b>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                {t('dashboard.preapproval.results.researchMessage')}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>{t('dashboard.preapproval.results.purchaseLabel')}:</b>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                {t('dashboard.preapproval.results.purchaseMessage')}
                            </Col>
                        </Row>
                    </Card.Text>
                </>
            }
        </Card>
    );
}

export default ResultsTabContent;
