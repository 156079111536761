import React from 'react';
import { useEffect } from 'react';
import { Navbar } from 'react-bootstrap';
import { Link, Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import CenteredContainer from 'components/common/CenteredContainer';

import Registration from 'components/registration';
import LoginForm from 'components/login';
import ForgotPasswordForm from 'components/forgotPassword';
import ResetPasswordForm from 'components/passwordReset';

import LanguageToggle from 'components/language';

import { 
  doesTokenExistLocallyAndIsValidIfSo
} from 'components/utils';


import logo from 'assets/img/logos/homr_white.png';
import colors from 'colors';

const BaseLayout = ({children}) => {
    const navigate = useNavigate();
    useEffect(() => {
      if (doesTokenExistLocallyAndIsValidIfSo()) {
        navigate('/dashboard');
      }

    }, [navigate]); // navigate is a dependency

  return (
    <>
      <Navbar expand="lg" style={{ 
        backgroundColor: colors.darkBlue
      }}>
        <Link to={"/"}>
          <img className="me-2" src={logo} alt="Logo" width={"80%"} />
        </Link>
        <LanguageToggle />
      </Navbar>
      <CenteredContainer>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} /> 
          <Route path="/login" element={<LoginForm />} />
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route path="/reset-password" element={<ResetPasswordForm />} />
          <Route path="/registration" element={<Registration />} />
        </Routes>
      {/* </div> */}
      </CenteredContainer>
    </>
  );
};

export default BaseLayout;
