import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import colors from '../../colors';
var landingButtonStyles = {
  color: colors.darkBlue,
  borderColor: colors.darkBlue,
  backgroundColor: "white",
  textDecoration: 'none'
}

var hasPreApprovalResultLandingButtonStyles = {
  color: colors.darkBlue,
  borderColor: colors.terquoise,
  backgroundColor: "white",
  textDecoration: 'none'
}

var disabledLandingButtonStyles = {
  color: "gray", 
  borderColor: "gray",
  background: "white",
  pointerEvents: "none",
  textDecoration: 'none'
}

const LandingButton = ({ source, alt, text, linkTo, isDisabled, hasPreapprovalResult }) => {
  return (
    <Link to={linkTo} style={isDisabled ? disabledLandingButtonStyles : landingButtonStyles}>
        <Button 
            // variant="primary" 
            style={isDisabled ? disabledLandingButtonStyles : (hasPreapprovalResult ? hasPreApprovalResultLandingButtonStyles : landingButtonStyles)}
            size="lg" 
            disabled={isDisabled}>
        <img 
            src={source}
            alt={alt}
            width="32" 
            height="32" 
            />
        {text}
        </Button>
    </Link>
  );
};

export default LandingButton;